import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import logo from './logo.png';

  // bar animation
    function animateBars() {
      const bars = document.querySelectorAll('.bar');
      bars.forEach((bar) => {
          bar.style.width = bar.getAttribute('data-target-width');
      });
  }
  window.onload = function() {
      setTimeout(animateBars, 2000); 
  };
  // 

const App = () => {
  
  const [showScroll, setShowScroll] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const checkScrollTop = useCallback(() => {
    if (window.pageYOffset > 400) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollPos > currentScrollPos) {
      document.getElementById('navbar').style.transform = 'translateY(0)';
    } else {
      document.getElementById('navbar').style.transform = 'translateY(-100%)';
    }
    setPrevScrollPos(currentScrollPos);
    checkScrollTop();
  }, [prevScrollPos, checkScrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const links = document.querySelectorAll('.Navbar-links a');
    const handleClick = () => setIsMobileMenuVisible(false);

    links.forEach(link => link.addEventListener('click', handleClick));

    return () => {
      links.forEach(link => link.removeEventListener('click', handleClick));
    };
  }, []);

  useEffect(() => {
    const aboutSection = document.getElementById('about');
    const portfolioSection = document.getElementById('portfolio');
    const collabSection = document.getElementById('collab');
    const servicesSection = document.getElementById('services');
    const processSection = document.getElementById('process');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        }
      });
    });

    if (aboutSection) observer.observe(aboutSection);
    if (portfolioSection) observer.observe(portfolioSection);
    if (servicesSection) observer.observe(servicesSection);
    if (collabSection) observer.observe(collabSection);
    if (processSection) observer.observe(process);

    return () => {
      observer.disconnect();
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (event) => {
    event.preventDefault();
    const targetId = event.target.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - document.querySelector('.Navbar').offsetHeight,
        behavior: 'smooth',
      });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div className="App">
      <nav className="Navbar" id="navbar">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <div className="name-subtext">
            <div className="name">
              HEAL<span className="animate-right">SEQ</span>
            </div>
            <div className="subtext">A Precision Medicine and Health Systems company</div>
          </div>
        </div>
        <div className={`Navbar-links ${isMobileMenuVisible ? 'show-mobile' : ''}`}>
          <a href="#about" onClick={scrollToSection}>About Us</a>
          <a href="#portfolio" onClick={scrollToSection}>Portfolio</a>
          <a href="#services" onClick={scrollToSection}>Services</a>
        </div>
        <div className="Navbar-collapse-btn" onClick={toggleMobileMenu} aria-label="Toggle mobile menu" role="button" tabIndex={0}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>

      <div className="video-container">
        <video id="background-video" className="background-video" autoPlay muted>
          <source src="/clips.mp4" type="video/mp4" />
        </video>
      </div>

      <section id="about" className="Section About">
        <h2>About Us</h2>
        <div className="about-content">
          <div className="about-image">
            <img src="/images/healseq.png" alt="Illustration representing HealSeq's work" />
          </div>
          <div className="about-text">
            <p className="about-paragraph animate-left">
              HealSeq is a deep-tech company incubated at IISc, Bangalore, founded by experienced scientists with the mission of advancing precision medicine. We have over 70 years of combined experience in genomics, bioinformatics, systems biology, pharmacology, structural biology, drug discovery, as well as systems engineering, AI and ML, and big data analytics.
            </p>
            <p className="about-paragraph animate-right">
              The goal is powering precision medicine and personalization in disease prognosis, diagnosis and
              treatment via integrated analysis of clinical, genomic and transcriptomic patient data. We depart
              from the one-size-fits all approach and embark on the vision to ensure high quality affordable
              healthcare for all through our integrated systems engineering approach and value-engineering.
            </p>
          </div>
        </div>

        <br></br>
      </section>

      <section className="parallax2">
      </section>

      <section id="portfolio" className="Section Portfolio">
        <h2>Portfolio</h2>
        <div className="cards-container">
          <div className="card">
            <img src="/images/pic2biomarker.png" alt="DNA strand" className="card-image" />
            <div className="card-content">
              <h3>Biomarkers</h3>
              <p>Our Clinical Blood-based Biomarker Panels
                discovered via transcriptomic analysis, systems modelling and AI with precise clinical scores to facilitate personalised treatment.</p>
            </div>
          </div>
          <div className="card">
            <img src="/images/pic8onc.png" alt="Precision Oncology" className="card-image" />
            <div className="card-content">
              <h3>Precision Oncology</h3>
              <p> Unlock personalized cancer care with assessment for molecular subtype, early prognosis and detection, treatment response and clinical decision support for individual therapy recommendation.</p>
            </div>
          </div>
          <div className="card">
            <img src="/images/pic5amr.png" alt="AMR" className="card-image" />
            <div className="card-content">
              <h3>AMR</h3>
              <p>Precision mapping of resistance mechanism based on genomic and transcriptomic profiling, integrated Health Systems guide for Antibiotic Stewardship and Host RNA Biomarkers for differential diagnosis.</p>
            </div>
          </div>
          <div className="card">
            <img src="/images/pic4digital.png" alt="digitalhealthcare" className="card-image" />
            <div className="card-content">
              <h3>Digital Health</h3>
              <p>Healseq's in-house models aid in accurate TB prediction with CNN-driven deep learning through comprehensive integration of clinical, genomic, laboratory, radiological, demographicand socioeconomic patient data.</p>
            </div>
          </div>
          <div className="card">
            <img src="/images/pic9pretox.png" alt="Precision Toxicology" className="card-image" />
            <div className="card-content">
              <h3>Precision Toxicology</h3>
              <p>A systems-based approach from integrated transcriptomics to curated toxicology reports, ranking of drug candidates, predictive modelling of Toxicity mechanisms and Adverse Effect outcomes
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax3">
      </section>

      <section id="process" className="Section process">
        <h2>Biomarkers</h2>
        <div class="chart-container">
          <div class="chart-header">
            <div></div>
            <div>Pipeline Discovery</div>
            <div>Assay Development</div>
            <div>Pilot Validation</div>
            <div>Clinical Validation</div>
            <div>Commercialisation</div>
          </div>
          <div class="chart">
            <div class="chart-row">
              <div class="label">TB Cure-Dx</div>
              <div class="bar-container">
                <div class="bar" data-target-width="68%"></div>
              </div>
            </div>
            <div class="chart-row">
              <div class="label">Asthma-COPD Biomarker</div>
              <div class="bar-container">
                <div class="bar" data-target-width="50%"></div>
              </div>
            </div>
            <div class="chart-row">
              <div class="label">Infection Response Panel
              </div>
              <div class="bar-container">
                <div class="bar" data-target-width="50%"></div>
              </div>
            </div>
            <div class="chart-row">
              <div class="label">BRCADx Breast cancer</div>
              <div class="bar-container">
                <div class="bar" data-target-width="19%"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax">
      </section>

      <section id="services" className="Section services">
        <h2>Our Services</h2>
        <p>The company provides services tailored to the healthcare sector. Based on the research experience and technology understanding, the company offers services in leveraging Omics & Clinical data to identify biologically and clinically significant insights. This includes genomic and transcriptomic analysis, drug discovery, protein structure modelling, biomarker discovery, AMR analysis and individual personalized medicine services. It also works in digital health and It offers expertise in systems development for digital health. Our highly experienced team navigates challenges seamlessly, catering to your health and diagnostic needs.</p>
        <br></br>
        <div className="services-cards-container">
          {/* <div className="service-card">
            <img src="/images/immune-booster.png" alt="Immune Profiling and Therapy" className="service-card-image" />
            <div className="card-content">
              <h4>Immune Profiling and Therapy</h4>
              <p>We can provide personalised immune status, Immunotherapy
                prognosis and candidate therapy evaluation.</p>
            </div>
          </div> */}
          <div className="service-card">
            <img src="/images/serviceGenomedata.png" alt="Genomic Wellness" className="service-card-image" />
            <div className="card-content">
              <h4>Genome Data Analysis</h4>
              <p>We work with our customers in precision medicine to solve problems in engineering, bioinformatics, science, and translational medicine that involve clinical and omics data.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="/images/serviceOmics.png" alt="Multi-Omics Analysis" className="service-card-image" />
            <div className="card-content">
              <h4>Multi-Omics Analysis</h4>
              <p>We help you accelerate variant curation, reporting, and interpretation of multi-omics, single cell, and spatial transcriptomics data to address domain-specific biological problems.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="/images/serviceDrug.png" alt="Targeted Drug Discovery" className="service-card-image" />
            <div className="card-content">
              <h4>Drug Discovery</h4>
              <p>We do structure-based lead and target identification, drug repurposing, mechanism of action prediction, and druggability prediction.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="/images/serviceBiochem.png" alt="Multi-Omics Analysis" className="service-card-image" />
            <div className="card-content">
              <h4>Bioinformatics and Chemoinformatics</h4>
              <p>Focusing on sequence analysis, molecular modeling, and drug design. Tailored to support life sciences and chemistry research, our solutions deliver precise, data-driven insights to enhance and accelerate your projects.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="/images/serviceNatural.png" alt="Genomic Wellness" className="service-card-image" />
            <div className="card-content">
              <h4>Natural Compound Pharmacology Profiler</h4>
              <p>An integrative approach utilising high throughput omics data alongside network biology to elucidate the complex mechanisms and pathways involved in the biological effects and therapeutic potential of natural compounds.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax3">
      </section>

      <section id="collab" className="Section collab">
        <h2>Funding</h2>
        <br></br>
        <div className="collab-images-container">
          <img src="/images/SID.png" alt="Research Collaboration" className="collab-image" />
          <img src="/images/collab4.png" alt="Education and Training" className="collab-image" />
          <img src="/images/collab1.png" alt="Partnership Opportunities" className="collab-image" />
          <img src="/images/collab5.png" alt="Education and Training" className="collab-image" />
          <img src="/images/collab2.png" alt="Research Collaboration" className="collab-image" />
          <img src="/images/collab3.png" alt="Innovation Projects" className="collab-image" />
        </div>
      </section>

      <footer className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <h2>Healseq</h2>
            <p>A Precision medicine and Health systems company incubated by IISc</p>
            <p>Incorporated as Healthseq Precision Medicine Private Limited in December 2019</p>
            <p>Fully compliant under MCA, GST</p>
            <p>Registered under MSME: Micro, Startup India</p>
          </div>
          <div className="footer-right">
            <p>Phone: +919845908205</p>
            <p>Email: info@healseq.com</p>
            <p>WHERE TO FIND US:</p>
            <p>Entrepreneur Centre, Society for Innovation and Development,</p>
            <p>(SID), Innovation Centre, IISC Campus, Bangalore 560012, India</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Healseq</p>
          <button onClick={scrollTop} id="myBtn" title="Go to top" className={`scroll-top-btn ${showScroll ? 'show' : ''}`} aria-label="Scroll to top">
          </button>
        </div>
      </footer>
    </div>
  );
};

export default App;
